.dx-widget, .dx-widget *, .dx-widget ::after, .dx-widget ::before, .dx-widget::after, .dx-widget::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 15px;
}

.list {
    width: 230px;
    /* border-right: 1px solid #ddd; */
}

.menuDiv {
    margin-left: 10px;
    width: 100%;
    padding-right: 5px;
    display: inline;
}

.menuLink {
    text-decoration: none;
    color: black;
    margin-left: 10px;
    width: 100%;
    display: block;
    line-height: 24px;
    font-size: 16px;
    padding-right:5px;
}
.logo {
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    white-space: normal;
    text-align: center;
    color: white;
    padding-left: 5px;
}

ul.dx-menu-items-container{
    width: 100%;
}

div.dx-drawer-panel-content{
    background-color: whitesmoke;
}

div.list div.dx-menu ul.dx-menu-items-container li.dx-menu-item-wrapper{
    min-height: 45px;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    margin: 10px 0 !important;
}

div.list div.dx-menu ul.dx-menu-items-container li.dx-menu-item-wrapper:hover{
    transform: scale(1.15, 1.15);
    padding-left: 15px;
}

div.dx-menu-item-content{
    min-height: 45px;
}

div.dx-drawer-panel-content{
    border-right: 1px solid #ddd;
}